<template>
  <div class="me-my-groups">
    <div class="me-my-groups__search">
      <i class="me-icon-back" @click="$router.go(-1)"></i>
      <van-search
        v-model="keyword"
        
        left-icon
        @search="handlerOnSearch(pageNum)"
        @cancel="handlerOnCancel"
      >
      <!-- :placeholder="$t('Please enter a search term')" -->
       <!-- show-action -->
        <div slot="right-icon" @click="handlerOnSearch(pageNum)">
          <i class="me-icon-search btn"></i>
        </div>
      </van-search>
    </div>
    <van-loading v-if="loading" class="loading" color="#33cc66" />
    <!-- <contactList class="me-my-groups__list" v-else-if="!loading && list.length" :list="list" /> -->
    <div class="me-my-groups__list"  v-else-if="!loading && list.length" 
     v-scroll="{
      distance: 150,
      onTop: _onTop,
      onBottom: _onBottom
    }">
    <van-cell  v-for="(item, index) in list" :key="index" >       
          <Avatar
            :src="item.senderPhoto"
            width="36px"
            height="36px"
            :isGroup="item.isGroup"
            :id="item.senderId"
          />
          <!-- $router.push({name:item.isGroup? 'group':'user',params:{id:item.senderId}}): '' -->
          <span class="name" @click="handlerToChat({id:item.senderId,name:item.senderName},'group')" v-html="lightHeight(item.senderName,keyword)"></span>
          <i v-if="item.isGroup && item.owner" class="me-icon-user-solid owner"></i>
    </van-cell>
    </div>
    <emptyData v-else />
  </div>
</template>
<script>
import Avatar from "@/components/common/avatar";
import { getGroups, searchGroups } from "@/api/imchat";
import emptyData from '@/components/common/emptyData'
import {lightHeight} from '@/utils'
import { Base64 } from "js-base64";
export default {
  name: "my-groups",
  components: {
    Avatar,
    emptyData
  },
  data() {
    return {
      language: "",
      pageNum: 1,
      pageSize: 50,
      list: [],
      keyword: "",
      loading: true,
      firstSearch:false
    };
  },
  watch:{
    keyword:{
      handler(newly){
        console.log(newly,'/keyword');        
        this.pageNum = 1
      },
      deep:true
    }
  },
  created() {
    this.language = localStorage.getItem("langCode") || "1";
    this.handlerGetMyGroups();
    console.log(this.list,'/list');
    
  },
  methods: {
    lightHeight,
    handlerToChat(item,type){
      localStorage.setItem('chatObj',JSON.stringify({
        id:item.id,
        name:item.name,
        notice:false,
        type
      }))
      this.$router.push({
        name:'chatWith',
        params:{
          id:"pai_" + Base64.encode(item.id),
          type:type
        }
      })
    },
    _onTop(){},
    _onBottom(){
      this.pageNum++
      console.log(this.pageNum,'/this.pageNum');
      
      if(this.keyword){
        this.handlerOnSearch(this.pageNum)
      }       
      else this.handlerGetMyGroups(this.pageNum)
    },
    async handlerOnSearch(page = 1) {
      const params = {
        language: this.language,
        user_id: this.$store.state.User.userinfo.id,
        key: this.keyword,
        pageNum: page,
        pageSize: this.pageSize
      };
      const result = await searchGroups(params).catch(e => {
      });
      if (result && result.data){
        const list = result.data.map(item => {
          return {
            senderName: item.group_name,
            senderPhoto: item.group_header,
            senderId: item.group_id,
            isGroup: true,
            owner: item.group_owner === this.$store.state.User.userinfo.id
          };
        });
        if(this.firstSearch)
          this.list = page == 1 ? list : this.list.concat(list)
        else {
          this.list = list
          this.firstSearch = true
        }
      }
        
    },
    handlerOnCancel() {
      this.keyword = "";
      this.pageNum = 1
    },
    async handlerGetMyGroups(page = 1) {
      this.firstSearch = false
      const params = {
        language: this.language,
        user_id: this.$store.state.User.userinfo.id,
        pageNum: page,
        pageSize: this.pageSize

        // page: this.pageSize
      };
      const result = await getGroups(params).catch(e => {
        console.log(e);
      });
      this.loading = false;
      if (result && result.data) {
        const list = result.data.map(item => {
          return {
            senderName: item.group_name,
            senderPhoto: item.group_header,
            senderId: item.group_id,
            isGroup: true,
            owner: item.group_owner === this.$store.state.User.userinfo.id
          };
        });
        this.list = page == 1 ? list : this.list.concat(list)
      } 
      // else this.list = this.list.length ? this.list : []
    }
  }
};
</script>
<style lang="stylus">
@import '~styles/utils/index';

+b(me-my-groups) {
  display:flex;
  flex-flow:column;
  min-height:100vh
  +e(list){
    margin 54px 0 0;
    height 93vh;
    overflow-y scroll
    .van-cell{
      display flex;
      flex-flow row;
      align-items center
      +e(value){
        display flex;
        flex-flow row
        align-items center;
        .name{
          flex 1
          white-space nowrap
          overflow hidden
          text-overflow ellipsis
        }
        .owner{
          color #33cc66
          font-size 16px
        }
      }
    }
  }

  +e(search) {
    flex: 1;
    // padding: 0 0.9rem;
    display: flex;
    flex-flow: row;
    position: fixed;
    align-items: center;
    top: 0;
    left: 0;
    background: #33cc66;
    color: #ffffff;
    width: 100%;
    z-index: 3000;
    height: 54px;

    i {
      margin: 0 0 0 0.9rem;
    }

    .van-search {
      // padding: 0.9rem;
      flex: 1;
      // border-radius: 0.2rem;
      background: none !important;
    }
  }
}
</style>