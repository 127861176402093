var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "me-my-groups" },
    [
      _c(
        "div",
        { staticClass: "me-my-groups__search" },
        [
          _c("i", {
            staticClass: "me-icon-back",
            on: {
              click: function($event) {
                return _vm.$router.go(-1)
              }
            }
          }),
          _c(
            "van-search",
            {
              attrs: { "left-icon": "" },
              on: {
                search: function($event) {
                  return _vm.handlerOnSearch(_vm.pageNum)
                },
                cancel: _vm.handlerOnCancel
              },
              model: {
                value: _vm.keyword,
                callback: function($$v) {
                  _vm.keyword = $$v
                },
                expression: "keyword"
              }
            },
            [
              _c(
                "div",
                {
                  attrs: { slot: "right-icon" },
                  on: {
                    click: function($event) {
                      return _vm.handlerOnSearch(_vm.pageNum)
                    }
                  },
                  slot: "right-icon"
                },
                [_c("i", { staticClass: "me-icon-search btn" })]
              )
            ]
          )
        ],
        1
      ),
      _vm.loading
        ? _c("van-loading", {
            staticClass: "loading",
            attrs: { color: "#33cc66" }
          })
        : !_vm.loading && _vm.list.length
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "scroll",
                  rawName: "v-scroll",
                  value: {
                    distance: 150,
                    onTop: _vm._onTop,
                    onBottom: _vm._onBottom
                  },
                  expression:
                    "{\n    distance: 150,\n    onTop: _onTop,\n    onBottom: _onBottom\n  }"
                }
              ],
              staticClass: "me-my-groups__list"
            },
            _vm._l(_vm.list, function(item, index) {
              return _c(
                "van-cell",
                { key: index },
                [
                  _c("Avatar", {
                    attrs: {
                      src: item.senderPhoto,
                      width: "36px",
                      height: "36px",
                      isGroup: item.isGroup,
                      id: item.senderId
                    }
                  }),
                  _c("span", {
                    staticClass: "name",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.lightHeight(item.senderName, _vm.keyword)
                      )
                    },
                    on: {
                      click: function($event) {
                        return _vm.handlerToChat(
                          { id: item.senderId, name: item.senderName },
                          "group"
                        )
                      }
                    }
                  }),
                  item.isGroup && item.owner
                    ? _c("i", { staticClass: "me-icon-user-solid owner" })
                    : _vm._e()
                ],
                1
              )
            }),
            1
          )
        : _c("emptyData")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }